<template lang="pug">
  .account-management
    AppOverlayLoader(:state="rolesLoading")
    CreateAccount(
      :roles="roles"
      :roles-loading="rolesLoading"
    )
</template>

<script>
  // store modules
  import rolesModule from "@/config/store/roles"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"

  const rolesMixin = withStoreModule(rolesModule, {
    name: "roles",
    readers: { roles: "items", rolesLoading: "loading" },
    actions: {
      fetchRoles: "FETCH_ITEMS",
      createRole: "CREATE_ITEM",
      updateRole: "UPDATE_ITEM",
      deleteRole: "DELETE_ITEM"
    }
  })

  export default {
    components: {
      CreateAccount: () => import("./CreateAccount"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader")
    },

    mixins: [rolesMixin],

    data() {
      return {}
    },

    created() {
      this.fetchRoles({ forceReload: false })
    }
  }
</script>

<style lang="sass" scoped></style>
